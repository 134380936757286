import React from 'react';
import './Contact.scss';

function Contact() {
  return (
    <section className="contact-section">
      <div className="title-wrapper">
        <h1 className="title">Let's Connect</h1>
        <img src="/svgs/lightning.svg" alt="" className="lightning-svg" />
      </div>
      <div className="description-wrapper">
        <p className="description">
          Thank you for visiting my portfolio. If you're interested in working together, feel free to reach out—I'd love to connect!
        </p>
        <img src="/svgs/curly-arrow.svg" alt="" className="curly-arrow-svg" />
      </div>

      <div className="email-container">
        <a className="jane-button" href="mailto:Janeqin1105@gmail.com">
          Janeqin1105@gmail.com
        </a>
      </div>
      <div className="social-icons">
        <a className="jane-button" href="/pdfs/Jiayun_Resume.pdf" target="_blank">
          <img src="/svgs/download.svg" alt="Instagram" className="icon" />
        </a>
        <a className="jane-button" href="https://www.instagram.com/janeeee.arts/" target="_blank" rel="noopener noreferrer">
          <img src="/svgs/instagram.svg" alt="Instagram" className="icon" />
        </a>
        <a className="jane-button" href="https://www.linkedin.com/in/jiayun-q-365a41aa" target="_blank" rel="noopener noreferrer">
          <img src="/svgs/linkedin.svg" alt="LinkedIn" className="icon" />
        </a>
      </div>
      <img src="/svgs/leaf.svg" alt="" className="leaf-svg" />
      <p className="footer-text">Made with a lot of ❤️ + steep tea. © 2024 Jiayun Qin</p>
    </section>
  );
}

export default Contact;