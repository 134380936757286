import React, { useEffect } from 'react';
import ImageCarousel from '../ImageCarousel/ImageCarousel';

import './PriorityRenewalProject.scss';

function PriorityRenewalProject() {
  useEffect(() => {
    document.body.style.backgroundColor = '#C9F5FF';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);
  return (
    <div className="project-1-container">
      <div className="project-1-header">
        <div className="header-content">
          <div className="header-title">Priority Renewal Project</div>
          <div className="header-subtitle">Website Design</div>
        </div>
        <div className="header-image">
          <img src="/images/project1-header.png" alt="Priority Renewal Project website on desktop screen" />
        </div>
      </div>
      <div className="project-1-content">
        <div className="project-1-overview">
          <div className="title">Overview</div>
          <div className="overview-content">
            <div className="overview-description">
              <p>
                The Priority Renewal Project was my first UX project during my co-op
                term at the Public Service Commission of Canada. It is a hiring system
                used by the government to prioritize the employment of former
                government employees and veterans. The user journey involves
                creating an account, searching for job opportunities, filling out
                applications, and managing saved job listings and applied positions.
              </p>
            </div>
            <div className="overview-details">
              <div className="detail-item">
                <div className="detail-title">My role</div>
                <div>UX designer (Co-op student)</div>
              </div>
              <div className="detail-item">
                <div className="detail-title">Tools</div>
                <div>Microsoft PowerPoint</div>
                <div>Optimal Workshop</div>
              </div>
              <div className="detail-item">
                <div className="detail-title">Duration</div>
                <div>Sep 2016 - Dec 2016</div>
              </div>
            </div>
          </div>
          <div className="project-goal">
            <div className="title">Goal</div>
            <div className="goal-content">
              The goal of the Priority Renewal Project is to improve the search functionality and navigation, simplify the application process, and
              enhance the overall user experience. By redesigning the system with a more intuitive, visually engaging UI, the aim is to provide
              users with a smoother workflow—from finding job postings to applying, tracking, and managing their applications.
            </div>
          </div>
        </div>
      </div>
      <div className="project-1-process" style={{ paddingBottom: '40px' }}>
        <div className="title">UX design process</div>
        <img style={{ maxWidth: '680px' }} src="/images/project-1-process.png" alt="Project process" />
      </div>
      <div className="project-1-content">

        <div className="project-1-details">
          <div className="discovery-title">
            <div className="discovery-icon-container">
              <img src="/svgs/discovery.svg" alt="Discovery icon" className="discovery-icon" />
              <div className="discovery-title-text">Discovery</div>
            </div>
          </div>
          <div className="title">Problems in the job search page</div>
          <ul className="problems-list">
            <li>General job seekers may not understand the meaning of tab names when they are presented as acronyms (NAPA, NAA).</li>
            <li>Each job listing occupies different amounts of space, and some are overly wordy.</li>
            <li>The job summary results remain the same whether users select all or none of the filters for "job type" and "minimum salary," which appears to be a technical bug.</li>
            <li>There is no option to favorite or save a job listing, preventing users from keeping track of positions they are interested in revisiting or applying for later.</li>
            <li>Adding an organization filter requires three steps: typing the organization name, adding it, and then searching.</li>
          </ul>
          <div className="screenshot-container">
            <img src="/images/job-search-page.jpeg" alt="Job search page screenshot" className="job-search-screenshot" />
          </div>

          <div className="title">Problems with the applying process</div>
          <ul className="problems-list">
            <li>There are too many steps to complete one application—15 in total.</li>
            <li>There is no connection between each step. For example, after completing the resume step, I can’t move directly to the screening questions. I have to return to the dashboard and select the next step manually.</li>
            <li>Resume, education, and language information are already stored in users' accounts, but users are required to input this information manually again during the application process.</li>
            <li>
              <b>Good part:</b> I like that there is a requirements list with icons indicating which requirements have been completed.
            </li>
          </ul>
          <div className="screenshot-container">
            <img src="/images/flow1.png" alt="flow1" className="job-search-screenshot" />
          </div>
          <div className="full-full-button">
            <a className="jane-button jane-button-white" href="/pdfs/PRP-UX-general_test_site_analysis.pdf" target="_blank">
              View full analyze file
              <span className="arrow">
                <img src="/svgs/arrow.svg" alt="Arrow" />
              </span></a>
          </div>
        </div>

        <div className="project-1-details">
          <div className="discovery-title">
            <div className="discovery-icon-container">
              <img src="/svgs/ideation.svg" alt="Ideation icon" className="Ideation-icon" />
              <div className="discovery-title-text">Ideation</div>
            </div>
          </div>
          <div >Based on the discovery and analysis, I proposed several design solutions aimed at addressing the UI issues and improving the process.</div>
          <ul className="problems-list">
            <li>Avoid using acronyms or placing a question icon beside them; instead, allow users to click on the acronym or the question icon for an explanation.</li>
            <li>Design a clear format and template for job listings, or hide overly wordy sections.</li>
            <li>Set the default filter option to select "all" including a sub-list.</li>
            <li>Add a save function for job listings.</li>
            <li>Simplify the process by combining some pages to gather information.</li>
            <li>Provide a "Continue" button on each information page to allow users to move directly to the next step instead of returning to the dashboard.</li>
            <li>For information already stored in the user account, the system should auto-fill the form with this data instead of requiring users to input it again, or it should ask whether they want to update it.</li>
          </ul>
          <div className="screenshot-container">
            <img src="/images/flow2.png" alt="Job search page screenshot" className="job-search-screenshot" />
          </div>

        </div>


        <div className="project-1-details">
          <div className="discovery-title">
            <div className="discovery-icon-container">
              <img src="/svgs/prototype.svg" alt="prototype icon" className="prototype-icon" />
              <div className="discovery-title-text">Prototype</div>
            </div>
          </div>
          <div className="title">Style guide</div>
          <div>
            The updates to the style guide were subtle regarding design consistency, and my primary focus was on modernizing it to achieve a clean and contemporary look. This was essential as we were integrating the design into a broader government website system that demanded a cohesive design approach.
          </div>
          <div className="screenshot-container">
            <img src="/images/prp style.png" alt="prp style.png" className="job-search-screenshot" />
          </div>
          <div className="title">High-fidelity prototype</div>
          <div>
            To design the prototype, I use Microsoft PowerPoint, the only tool available from my organization. Throughout the process, I made a conscious effort to follow the government style tone outlined in the style guide while ensuring that the final product maintained a professional appearance. Upon completion, I was happy to find that the visual design of the prototype aligned well with the existing government website. This integration meant that the prototype seamlessly fit into the current aesthetics, providing a cohesive user experience for visitors.
          </div>
        </div>
      </div>
      <div className="project-1-process all-pages-container">
        <img src="/images/all pages.png" alt="Project process" />
        <div className="full-full-button">
          <a className="jane-button jane-button-white" href="/pdfs/full prototype file.pdf" target="_blank">
            View full prototype file
            <span className="arrow">
              <img src="/svgs/arrow.svg" alt="Arrow" />
            </span></a>
        </div>
      </div>

      <div className="project-1-content">

        <div className="project-1-details">
          <div className="discovery-title">
            <div className="discovery-icon-container">
              <img src="/svgs/ideation.svg" alt="Ideation icon" className="Ideation-icon" />
              <div className="discovery-title-text">Test and refinement</div>
            </div>
          </div>
          <div className="title">First-click usability test</div>
          <div>Using the <span style={{ color: '#FF0000' }}>Optimal Workshops</span> tool, I conducted a first-click usability test with 32 participants from the government sector, representing diverse backgrounds. The objective was to assess the usability of the new UI design and identify any obstacles users might encounter. Participants started with completing a brief background survey, and then engaged in 10 tasks, where they clicked on designated target areas within the user interface. We measured the time each user took to complete these tasks. After analyzing the data, I evaluated the positioning of buttons within the interface to determine their optimal placement for ease of use. Overall, the goal of the usability test was to gather valuable insights to ensure the new UI design aligns with our design goals and supports sound design decisions.</div>
          <div className="title">Test questions/tasks</div>

          <ul className="problems-list">
            <li>Look for a job by job title.</li>
            <li>Search for a job available to you based on your priority status.</li>
            <li>Find how to keep the job in your account to apply for later.</li>
            <li>Where would you go to submit an application for this job?</li>
            <li>How would you keep the application to work on later?</li>
            <li>Find where to enter your education.</li>
            <li>You uploaded the wrong resume. Go back to change it.</li>
            <li>You decided not to apply. What do you do?</li>
            <li>You think your information is correct and you want to apply for the job. Where do you go?</li>
            <li>Find where to go to see everything you have applied for.</li>
          </ul>
          <div className="title">Test results</div>
          <div>
            Out of the 32 participants, 26 individuals (or 81%) successfully completed all 10 tasks, demonstrating that the new design is user-friendly. The tests allowed me to identify areas of concern that need attention. Additionally, gathering user feedback provided valuable insights into their needs and preferences, enabling me to enhance the system accordingly.
          </div>
          <div className="screenshot-container">
            <ImageCarousel
              images={[
                '/images/result/Result01.png',
                '/images/result/Result02.png',
                '/images/result/Result03.png',
                '/images/result/Result04.png',
                '/images/result/Result05.png',
                '/images/result/Result06.png',
                '/images/result/Result07.png',
                '/images/result/Result08.png',
                '/images/result/Result09.png',
                '/images/result/Result10.png',
                '/images/result/Result11.png',
                '/images/result/Result12.png',
                '/images/result/Result13.png',
                '/images/result/Result14.png',
                '/images/result/Result15.png'
              ]}
            />
          </div>
        </div>
      </div>

      <div className="learnings-container">
        <div className="learnings-content">
          <div className="title">Learnings & Takeaways</div>
          <div className="takeaways-content">This project has highlighted the importance of analysis during a system redesign and the critical role of testing in informing design decisions. The data gathered from testing serves as a powerful tool for understanding user perceptions, creating a direct connection between designers and users. Therefore, I’d like to incorporate more usability tests in my future design projects.</div>
        </div>
      </div>

    </div>
  );
}

export default PriorityRenewalProject;
